import { createGlobalStyle } from 'styled-components';

import { COLORS, GRADIENTS } from '@constants';

import './font-styles.css';

const GlobalStyles = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/* GLOBAL STYLES */
*,
*:before,
*:after {
  box-sizing: border-box;
  line-height: 1.45;
  -webkit-font-smoothing: antialiased;
}

html, body, #__next {
  height: 100%;
}

body {
  color: var(--color-text);
  background: var(--color-background);
}

a {
  color: var(--color-link);
  text-decoration: none;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;

  &:focus {
    outline: 5px auto currentColor;
  }
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
  }
}

sup {
  display: inline-block;
  font-size: 0.6em;
  transform: translateY(-40%);
}

em {
  font-style: italic;
}

body, input, button, select {
  font-family: var(--font-family);
  font-weight: var(--font-weight-light);
}

select {
  scrollbar-color: initial;
}

option {
  font-weight: var(--font-weight-medium);
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: var(--font-weight-bold);
}

h1, h2, h3, h4, h5, h6, p {
  text-rendering: optimizeLegibility;
}

p {
  margin-bottom: 1.65em;
  line-height: 1.45;
}

code {
  font-size: 0.95em;
}

/* Scrollbar and selection styles */
::selection {
  background-color: var(--selection-background-color) !important;
  color: var(--selection-text-color) !important;
  background-image: none !important;
  -webkit-text-fill-color: var(--selection-text-color) !important;
  -moz-text-fill-color: var(--selection-text-color) !important;
  background-image: none !important;
  background-clip: revert !important;
  -webkit-background-clip: revert !important;
  text-shadow: none !important;
}

* {
  /* Firefox */
  scrollbar-color: var(--color-gray-700) var(--color-background);
  scrollbar-width: thin;
}

@media (orientation: landscape) {
  ::-webkit-scrollbar {
    width: 10px;
    background-color: var(--color-gray-100);
  }
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--color-gray-700);
    border: 2px solid var(--color-gray-100);
  }
}

/* CSS Variables */
:root {
  color-scheme: dark;

  --font-weight-bold: 500;
  --font-weight-medium: 400;
  --font-weight-light: 300;

  --font-family: 'Wotfard', Futura, -apple-system, sans-serif;
  --font-family-mono: 'League Mono', 'Fira Mono', monospace;

  --color-text: ${COLORS.text};
  --color-background: ${COLORS.background};
  --color-blurred-background: ${COLORS.blurredBackground};
  --color-primary: ${COLORS.primary};
  --color-secondary: ${COLORS.secondary};
  --color-tertiary: ${COLORS.tertiary};
  --color-decorative: ${COLORS.decorative};
  --color-muted: ${COLORS.muted};
  --color-error: ${COLORS.error};
  --color-error-background: ${COLORS.errorBackground};
  --color-success: ${COLORS.success};
  --color-success-background: ${COLORS.successBackground};
  --color-alert: ${COLORS.alert};
  --color-alert-background: ${COLORS.alertBackground};
  --color-gray-100: ${COLORS.gray[100]};
  --color-gray-200: ${COLORS.gray[200]};
  --color-gray-300: ${COLORS.gray[300]};
  --color-gray-400: ${COLORS.gray[400]};
  --color-gray-500: ${COLORS.gray[500]};
  --color-gray-600: ${COLORS.gray[600]};
  --color-gray-700: ${COLORS.gray[700]};
  --color-gray-800: ${COLORS.gray[800]};
  --color-gray-900: ${COLORS.gray[900]};
  --color-gray-1000: ${COLORS.gray[1000]};
  --color-link: hsl(250deg 100% 77.5%);

  --selection-background-color: hsl(0deg 0% 100% / 1);
  --selection-text-color: hsl(0deg 0% 0% / 1);

  --gradient-buy: ${GRADIENTS.buy};

  /* HACK:
    Reach UI tests for loaded styles, but I'm providing my own.
    This is to avoid a noisy warning in dev.
  */
  --reach-dialog: 1;
}

/*
  HACK: For some reason, making certain requests with Paddle
  throws up a big page-wide loader. I manage the loading state
  myself though.
*/
.paddle-loader {
  display: none !important;
}
`;

export default GlobalStyles;
